<template>
  <v-row justify="center">
    <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
      <v-simple-table id="table-custom">
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="font-weight-bold">Riwayat Jabatan :</td>
              <td>
                <p class="mb-0">
                  <b>{{ summary.R_Jabatan.sum }}</b>
                  ({{ summary.R_Jabatan.tahun }})
                </p>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="$emit('to-detail', keyjab)"
                  ><v-icon small>keyboard_arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Riwayat Golongan :</td>
              <td>
                <p class="mb-0">
                  <b>{{ summary.R_Golongan.sum }}</b>
                  ({{ summary.R_Golongan.tahun }})
                </p>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="$emit('to-detail', keygol)"
                  ><v-icon small>keyboard_arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Riwayat Pendidikan :</td>
              <td>
                <p class="mb-0">
                  <b>{{ summary.R_Pendidikan.sum }}</b>
                  ({{ summary.R_Pendidikan.tahun }})
                </p>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="$emit('to-detail', keypen)"
                  ><v-icon small>keyboard_arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Riwayat Diklat :</td>
              <td>
                <p class="mb-0">
                  <b>{{ summary.R_Diklat.sum }}</b>
                </p>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="$emit('to-detail', keydik)"
                  ><v-icon small>keyboard_arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Riwayat Kursus :</td>
              <td>
                <p class="mb-0">
                  <b>{{ summary.R_Kursus.sum }}</b>
                </p>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="$emit('to-detail', keykur)"
                  ><v-icon small>keyboard_arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Riwayat Cuti :</td>
              <td>
                <p class="mb-0">
                  <b>{{ summary.R_Cuti.sum }}</b>
                </p>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="$emit('to-detail', keycut)"
                  ><v-icon small>keyboard_arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Riwayat Tanda Jasa :</td>
              <td>
                <p class="mb-0">
                  <b>{{ summary.R_Tanda_Jasa.sum }}</b>
                </p>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="$emit('to-detail', keytan)"
                  ><v-icon small>keyboard_arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Riwayat Kenaikan Gaji :</td>
              <td>
                <p class="mb-0">
                  <b>{{ summary.R_Gaji.sum }}</b>
                  ({{ summary.R_Gaji.tahun }})
                </p>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="$emit('to-detail', keygaj)"
                  ><v-icon small>keyboard_arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Riwayat Kinerja :</td>
              <td>
                <p class="mb-0">
                  <b>{{ summary.R_Kinerja.sum }}</b>
                  ({{ summary.R_Kinerja.tahun }})
                </p>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="$emit('to-detail', keykin)"
                  ><v-icon small>keyboard_arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Riwayat Angka Kredit :</td>
              <td>
                <p class="mb-0">
                  <b>{{ summary.R_Angka_Kredit.sum }}</b>
                </p>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="$emit('to-detail', keyang)"
                  ><v-icon small>keyboard_arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
import { mapRiwayatField } from "@/store/helpers";
import { mapEmployeeRootField } from "@/store/helpers";
import PegawaiService from "@/services/resources/pegawai.service";

export default {
  data() {
    return {
      summary: {
        R_Jabatan: {
          sum: 0,
          tahun: ""
        },
        R_Golongan: {
          sum: 0,
          tahun: ""
        },
        R_Pendidikan: {
          sum: 0,
          tahun: ""
        },
        R_Diklat: {
          sum: 0
        },
        R_Kursus: {
          sum: 0
        },
        R_Cuti: {
          sum: 0
        },
        R_Tanda_Jasa: {
          sum: 0
        },
        R_Gaji: {
          sum: 0,
          tahun: ""
        },
        R_Kinerja: {
          sum: 0,
          tahun: ""
        },
        R_Angka_Kredit: {
          sum: 0
        }
      }
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal"
    }),
    ...mapRiwayatField({
      keyang: "keyang",
      keycut: "keycut",
      keydik: "keydik",
      keygaj: "keygaj",
      keygol: "keygol",
      keyjab: "keyjab",
      keykin: "keykin",
      keykur: "keykur",
      keypen: "keypen",
      keytan: "keytan"
    })
  },
  methods: {
    handleToggle() {
      this.$emit("toggle");
    },
    // Service
    async getListRiwayatSummary() {
      try {
        this.loading = true;
        await PegawaiService.getListRiwayatSummary(this.personal.NIP)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.summary = {
                ...this.summary,
                R_Jabatan: {
                  ...this.summary.R_Jabatan,
                  sum: data.R_Jabatan.sum,
                  tahun: data.R_Jabatan.tahun
                },
                R_Golongan: {
                  ...this.summary.R_Golongan,
                  sum: data.R_Golongan.sum,
                  tahun: data.R_Golongan.tahun
                },
                R_Pendidikan: {
                  ...this.summary.R_Pendidikan,
                  sum: data.R_Pendidikan.sum,
                  tahun: data.R_Pendidikan.tahun
                },
                R_Diklat: {
                  ...this.summary.R_Diklat,
                  sum: data.R_Diklat.sum
                },
                R_Kursus: {
                  ...this.summary.R_Kursus,
                  sum: data.R_Kursus.sum
                },
                R_Cuti: {
                  ...this.summary.R_Cuti,
                  sum: data.R_Cuti.sum
                },
                R_Tanda_Jasa: {
                  ...this.summary.R_Tanda_Jasa,
                  sum: data.R_Tanda_Jasa.sum
                },
                R_Gaji: {
                  ...this.summary.R_Gaji,
                  sum: data.R_Gaji.sum,
                  tahun: data.R_Gaji.tahun
                },
                R_Kinerja: {
                  ...this.summary.R_Kinerja,
                  sum: data.R_Kinerja.sum,
                  tahun: data.R_Kinerja.tahun
                },
                R_Angka_Kredit: {
                  ...this.summary.R_Angka_Kredit,
                  sum: data.R_Angka_Kredit.sum
                }
              };
              console.log("this.summary", this.summary);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListRiwayatSummary();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.675rem;
  }
}
#table-custom .v-input {
  font-size: 0.675rem;
}
</style>
